<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="用户管理"
      class="px-5 py-3 mb-5"
    >
      <v-row class="mt-5">
        <v-col md="1">
          <v-btn
            depressed
            color="success"
            @click="addUserDialog = true"
          >
            <v-icon left>
              fa fa-user-plus
            </v-icon>
            新增
          </v-btn>
        </v-col>
        <v-col md="1">
          <v-text-field
            v-model="nickName"
            label="昵称"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="name"
            label="用户名（账号）"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="mobile"
            label="手机号"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col md="2">
          <v-autocomplete
            v-model="orgId"
            label="组织机构"
            :items="orgs"
            item-text="name"
            item-value="id"
            clearable
            outlined
            dense
          >
            <template v-slot:item="{ item }">
              <span
                v-for="i in item.level"
                :key="i"
                class="ml-5"
              />
              <v-list-item-content>
                <v-list-item-title v-html="item.name" />
                <v-list-item-subtitle v-html="item.value" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col md="4">
          <v-btn
            depressed
            color="success"
            @click="getUser"
          >
            <v-icon left>
              fa fa-search
            </v-icon>
            搜索
          </v-btn>
          <v-btn
            depressed
            color="info"
            @click="batchImportUsers = true"
          >
            <v-icon left>
              fa fa-sign-in
            </v-icon>
            批量导入
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="pageSize"
        show-select
        :loading="loading"
        loading-text="加载中... 请稍等"
        :server-items-length="pageLength"
        @update:page="changPage"
        @update:items-per-page="changPageSize"
      >
        <template v-slot:item.nickName="{ item }">
          <v-chip
            pill
          >
            <v-avatar left>
              <v-img :src="item.avatar ? item.avatar : userPhoto" />
            </v-avatar>
            {{ item.nickName && item.nickName !== '' ? item.nickName : '未指定' }}
          </v-chip>
        </template>
        <template v-slot:item.enabled="{ item }">
          <v-switch
            v-model="item.enabled"
            @click="setUserStatus(item)"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="mx-2"
            small
            color="info"
            @click="setRolesBtn(item)"
          >
            <v-icon
              small
              left
            >
              fa fa-user-secret
            </v-icon>
            设置角色与用户组
          </v-btn>
          <v-btn
            class="mx-2"
            small
            color="success"
            @click="upUserInfoDialog(item)"
          >
            <v-icon
              small
              left
            >
              mdi-pencil
            </v-icon>
            编辑
          </v-btn>
          <v-btn
            class="mx-2"
            small
            color="error"
            @click="delUser(item)"
          >
            <v-icon
              small
              left
            >
              fa fa-trash-o
            </v-icon>
            删除
          </v-btn>
        </template>
      </v-data-table>
      <v-row class="mt-5">
        <v-col md="12">
          <v-btn
            outlined
          >
            <v-icon left>
              fa fa-list
            </v-icon>
            批量设置分组
          </v-btn>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="addUserDialog"
      max-width="900px"
      class="dialog_box"
    >
      <v-card
        max-width="900"
      >
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>新增用户</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="addUserDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <div class="form_box">
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.name"
                      :rules="validRules.username"
                      label="用户名（账号）*"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.password"
                      :rules="validRules.password"
                      label="密码*"
                      persistent-hint
                      required
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.mobile"
                      :rules="validRules.mobel"
                      label="手机号*"
                      required
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.nickName"
                      :rules="validRules.nickName"
                      label="姓名(昵称)"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.office"
                      label="所在部门（官方全称）"
                      required
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.motto"
                      label="职位"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.email"
                      label="电子邮箱"
                      required
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="user.account.orgId"
                      :rules="validRules.orgId"
                      label="组织机构"
                      :items="orgs"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <span
                          v-for="i in item.level"
                          :key="i"
                          class="ml-5"
                        />
                        <v-list-item-content>
                          <v-list-item-title v-html="item.name" />
                          <v-list-item-subtitle v-html="item.value" />
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="user.account.weight"
                      label="排序"
                      placeholder="数值越大排序越靠后"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="addUser"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editUserInfoDialog"
      max-width="900px"
      class="dialog_box"
    >
      <v-card
        max-width="900"
      >
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>编辑用户信息</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="editUserInfoDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <div class="form_box">
            <v-container>
              <v-form
                ref="upUser"
                v-model="valid"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.name"
                      :rules="validRules.username"
                      label="用户名（账号）*"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.password"
                      :rules="validRules.password"
                      label="密码*"
                      persistent-hint
                      required
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.mobile"
                      :rules="validRules.mobel"
                      label="手机号*"
                      required
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.nickName"
                      :rules="validRules.nickName"
                      label="姓名(昵称)"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.office"
                      label="所在部门（官方全称）"
                      required
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.motto"
                      label="职位"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.email"
                      label="电子邮箱"
                      required
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="editUserInfo.account.orgId"
                      :rules="validRules.orgId"
                      label="组织机构"
                      :items="orgs"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <span
                          v-for="i in item.level"
                          :key="i"
                          class="ml-5"
                        />
                        <v-list-item-content>
                          <v-list-item-title v-html="item.name" />
                          <v-list-item-subtitle v-html="item.value" />
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUserInfo.account.weight"
                      label="排序"
                      placeholder="数值越大排序越靠后"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="upUserInfo"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="setRolesDialog"
      max-width="800px"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>设置角色与用户组</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="setRolesDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <div class="form_box">
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-treeview
                    v-model="roles"
                    selectable
                    shaped
                    hoverable
                    open-all
                    selection-type="independent"
                    :items="roleTree"
                    @input="selectRole"
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-treeview
                    v-model="teams"
                    selectable
                    shaped
                    hoverable
                    open-all
                    selection-type="independent"
                    :items="teamTree"
                    @input="selectTeam"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="setRole"
          >
            设置
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="thereAreUserDialog"
      max-width="800px"
    >
      <v-card
        max-width="900"
      >
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>用户信息库</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="thereAreUserDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <div class="form_box">
            <v-alert
              outlined
              type="warning"
              text
            >
              <h3>如果用户在用户信息库中已经存在，将无法新建同名账户。</h3>
              <p>
                1、选定用户所在组织结构，删除列表中无关的用户。<br>
                2、点击保存，即可将用户添加至系统中。<br>
                3、用户可使用原账号密码进行登录，如忘记密码，可在用户管理中进行重置。<br>
                4、如果用户是在本系统中存在，保存将导致原用户所属组织机构变更。
              </p>
            </v-alert>
            <v-autocomplete
              v-model="thereAreUserOrgId"
              label="组织结构"
              :items="orgs"
              item-text="name"
              item-value="id"
              clearable
              outlined
              dense
            >
              <template v-slot:item="{ item }">
                <span
                  v-for="i in item.level"
                  :key="i"
                  class="ml-5"
                />
                <v-list-item-content>
                  <v-list-item-title v-html="item.name" />
                  <v-list-item-subtitle v-html="item.value" />
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      用户名
                    </th>
                    <th class="text-left">
                      昵称
                    </th>
                    <th class="text-left">
                      电话
                    </th>
                    <th class="text-left">
                      操作
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(userinfo, index) in thereAreUsers"
                    :key="userinfo.name + index"
                  >
                    <td>{{ userinfo.name }}</td>
                    <td>
                      <v-chip
                        pill
                      >
                        <v-avatar left>
                          <v-img :src="userinfo.avatar" />
                        </v-avatar>
                        {{ userinfo.nickName && userinfo.nickName !== '' ? userinfo.nickName : '未指定' }}
                      </v-chip>
                    </td>
                    <td>{{ userinfo.mobile }}</td>
                    <td>
                      <v-btn
                        class="ma-2"
                        depressed
                        rounded
                        small
                        color="error"
                        @click="delUserinfo(index)"
                      >
                        <v-icon left>
                          fa fa-trash-o
                        </v-icon>
                        删除
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="addThereAreUser"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="batchImportUsers"
      fullscreen
    >
      <v-card
        max-width="900"
      >
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>批量导入用户</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="batchImportUsers = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col
              cols="3"
            >
              <v-card
                class="mx-auto"
              >
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>表格导入</v-toolbar-title>
                </v-toolbar>

                <v-card-actions class="pa-7">
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="importOrgId"
                        label="组织机构"
                        :items="orgs"
                        item-text="name"
                        item-value="id"
                        clearable
                        outlined
                        dense
                      >
                        <template v-slot:item="{ item }">
                          <span
                            v-for="i in item.level"
                            :key="i"
                            class="ml-5"
                          />
                          <v-list-item-content>
                            <v-list-item-title v-html="item.name" />
                            <v-list-item-subtitle v-html="item.value" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-file-input
                        v-model="exportExcelFile"
                        label="上传表格"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-btn
                        color="info"
                        block
                        @click="downloadTemplate"
                      >
                        <v-icon left>
                          fa fa-table
                        </v-icon>
                        下载表格模板
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-btn
                        color="success"
                        block
                        @click="exportExcel"
                      >
                        <v-icon left>
                          fa fa-cloud-upload
                        </v-icon>
                        上传表格
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-alert
                        dense
                        type="info"
                        class="pa-0"
                        outlined
                      >
                        请下载<strong class="mx-2">"表格模板"</strong> 导入用户信息。
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              cols="9"
            >
              <v-card
                class="mx-auto"
              >
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>批量创建</v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    color="success"
                    dark
                    depressed
                    @click="saveExcelData"
                  >
                    <v-icon left>
                      fa fa-floppy-o
                    </v-icon>
                    确认新增
                  </v-btn>
                </v-toolbar>
                <v-card-actions>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                用户名
                              </th>
                              <th class="text-left">
                                昵称
                              </th>
                              <th class="text-left">
                                电子邮箱
                              </th>
                              <th class="text-left">
                                手机号
                              </th>
                              <th class="text-left">
                                密码
                              </th>
                              <th class="text-left">
                                组织机构
                              </th>
                              <th class="text-left">
                                部门
                              </th>
                              <th class="text-left">
                                职位
                              </th>
                              <th
                                width="100"
                                class="text-right"
                              >
                                操作
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(list, index) in excelData"
                              :key="list[0] + index"
                            >
                              <td class="text-left">
                                {{ list[0] }}
                              </td>
                              <td class="text-left">
                                {{ list[1] }}
                              </td>
                              <td class="text-left">
                                {{ list[2] }}
                              </td>
                              <td class="text-left">
                                {{ list[3] }}
                              </td>
                              <td class="text-left">
                                {{ list[4] }}
                              </td>
                              <td class="text-left">
                                {{ list[5] }}
                              </td>
                              <td class="text-left">
                                {{ list[6] }}
                              </td>
                              <td class="text-left">
                                {{ list[7] }}
                              </td>
                              <td class="text-left">
                                <v-btn
                                  class="mx-2"
                                  small
                                  color="error"
                                  depressed
                                  @click="delExcelData(index)"
                                >
                                  <v-icon
                                    small
                                    left
                                  >
                                    fa fa-trash-o
                                  </v-icon>
                                  删除
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import userPhoto from '../../assets/user_photo.jpg'
  import jsonTree from '../../utils/tree'
  import { orderBy, cloneDeep } from 'lodash'
  import http from '../../api'
  export default {
    data () {
      return {
        importOrgId: '',
        exportExcelFile: null,
        valid: true,
        userInfo: null,
        userPhoto: userPhoto,
        addUserDialog: false,
        editUserInfoDialog: false,
        loading: false,
        setRolesDialog: false,
        setGroup: false,
        pageLength: 0,
        nowPage: 1,
        page: 0,
        pageSize: 15,
        name: '',
        mobile: '',
        nickName: '',
        role: '',
        rolesArr: [],
        roles: [],
        roleTree: [],
        team: '',
        teams: [],
        teamTree: [],
        orgId: '',
        orgTree: [],
        orgs: [],
        user: {
          name: '',
          nickName: '',
          password: '',
          mobile: '',
          email: '',
          office: '',
          motto: '',
          account: {
            orgId: '',
            weight: '',
          },
        },
        editUserInfo: {
          name: '',
          nickName: '',
          password: '',
          mobile: '',
          email: '',
          office: '',
          motto: '',
          account: {
            orgId: '',
            weight: '',
          },
        },
        thereAreUserDialog: false,
        thereAreUsers: [],
        thereAreUserOrgId: null,
        users: [],
        headers: [
          {
            text: '用户名',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: '昵称',
            value: 'nickName',
            sortable: false,
          },
          {
            text: '手机号',
            value: 'mobile',
            align: 'center',
            sortable: false,
          },
          {
            text: '启用',
            value: 'enabled',
            sortable: false,
          },
          {
            text: '操作',
            value: 'actions',
            align: 'end',
            sortable: false,
          },
        ],
        validRules: {
          username: [
            v => !!v || '用户名不能为空',
          ],
          mobel: [
            v => !!v || '手机号不能为空',
          ],
          nickName: [
            v => !!v || '姓名（昵称）不能为空',
          ],
          orgId: [
            v => !!v || '组织结构不能为空',
          ],
        },
        batchImportUsers: false,
        excelData: [],
      }
    },
    created () {
      this.getUser()
      this.getOrgs()
      this.getRoles()
      this.getTeams()
    },
    methods: {
      getUser () {
        this.loading = true
        this.$axios.get('/users', {
          params: {
            name: this.name,
            nickname: this.nickName,
            mobile: this.mobile,
            org: this.orgId,
            page: this.page,
            size: this.pageSize,
          },
        })
          .then(res => {
            const resData = res.data.data
            this.pageLength = resData.totalElements
            this.users = resData.content
            this.loading = false
          })
          .catch(err => {
            console.log(err)
          })
      },
      async addUser () {
        if (this.$refs.form.validate()) {
          await this.$axios.get('/users/query', {
            params: {
              mobiles: this.user.mobile,
            },
          })
            .then(res => {
              if (res.data.data.length > 0) {
                this.thereAreUserDialog = true
                this.thereAreUsers = res.data.data
              } else {
                // 获取用户所属站点
                this.user.site = localStorage.systemId
                this.$axios.post('/users', this.user)
                  .then(res => {
                    this.addUserDialog = false
                    this.$swal({
                      title: '添加成功',
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 1500,
                    })
                  })
                  .catch(err => {
                    this.$swal({
                      title: err.response.data.msg,
                      icon: 'error',
                      showCancelButton: false,
                    })
                  })
              }
            })
        }
      },
      delUser (user) {
        this.$swal({
          title: '你确定要删除"' + user.name + '"吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.$axios.delete(`/users/${user.id}`)
              .then(res => {
                this.getUser()
                this.$swal({
                  title: '删除成功',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch(err => {
                this.$swal({
                  title: '删除失败',
                  icon: 'error',
                  text: '原因:' + err.response.data.msg,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
      upUserInfoDialog (item) {
        this.editUserInfoDialog = true
        this.editUserInfo = item
      },
      upUserInfo () {
        if (this.$refs.upUser.validate()) {
          console.log(this.editUserInfo)
          this.$axios.put(`/users/${this.editUserInfo.id}`, this.editUserInfo)
            .then(res => {
              this.editUserInfoDialog = false
              this.$swal({
                title: '修改成功',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch(err => {
              this.$swal({
                title: '修改失败',
                icon: 'error',
                text: '原因:' + err.response.data.msg,
                confirmButtonText: '知道了',
              })
            })
        }
      },
      searchUser () {
        alert('123123')
      },
      toggle () {
        this.$nextTick(() => {
          if (this.likesAllFruit) {
            this.selectedFruits = []
          } else {
            this.selectedFruits = this.fruits.slice()
          }
        })
      },
      changPage (nowPage) {
        this.page = nowPage - 1
        this.getUser()
      },
      changPageSize (pageSize) {
        this.pageSize = pageSize
        this.getUser()
      },
      delUserinfo (index) {
        this.thereAreUsers.splice(index, 1)
      },
      addThereAreUser () {
        this.thereAreUsers.map(user => {
          this.$axios.put(`/users/${user.id}/account`, {
            orgId: this.thereAreUserOrgId,
          })
            .then(res => {
              this.thereAreUserDialog = false
              this.$swal({
                title: '添加成功',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        })
      },
      async getOrgs () {
        await this.$axios.get('/dictionaries/roles')
          .then(res => {
            const orgs = res.data.data
            this.orgs = orderBy(
              cloneDeep(orgs),
              ['level', 'weight'],
              ['asc', 'asc'],
            )
            this.orgTree = jsonTree(orgs, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getRoles () {
        await this.$axios.get('/dictionaries/roles2')
          .then(res => {
            const roles = res.data.data
            this.roleTree = jsonTree(roles, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getTeams () {
        await this.$axios.get('/dictionaries/teams')
          .then(res => {
            const teams = res.data.data
            this.teamTree = jsonTree(teams, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      setRolesBtn (item) {
        this.setRolesDialog = true
        this.userInfo = item
        this.roles = item.roles
        this.teams = item.roles
      },
      selectRole (val) {
        this.roles = val
      },
      selectTeam (val) {
        this.teams = val
      },
      setRole () {
        this.$axios.put(`/users/${this.userInfo.id}/groups`, {
          groupIds: this.roles.concat(this.teams),
        })
          .then(res => {
            this.setRolesDialog = false
            this.userInfo.roles = this.roles.concat(this.teams)
            this.$swal({
              title: '设置成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            this.$swal({
              title: '设置失败',
              icon: 'error',
              text: '原因:' + err.response.data.msg,
              confirmButtonText: '知道了',
            })
          })
      },
      // setTeamsBtn (item) {
      //   this.setTeamDialog = true
      //   this.userInfo = item
      //   this.roles = item.roles
      // },
      // setTeam () {
      //   this.$axios.put(`/users/${this.userInfo.id}/groups`, {
      //     groupIds: this.teams,
      //   })
      //     .then(res => {
      //       this.setTeamsDialog = false
      //       this.userInfo.roles = this.teams
      //       this.$swal({
      //         title: '设置成功',
      //         icon: 'success',
      //         showConfirmButton: false,
      //         timer: 1500,
      //       })
      //     })
      //     .catch(err => {
      //       this.$swal({
      //         title: '设置失败',
      //         icon: 'error',
      //         text: '原因:' + err.response.data.msg,
      //         confirmButtonText: '知道了',
      //       })
      //     })
      // },
      setUserStatus (item) {
        this.$axios.put(`/users/${item.id}/status`, {
          enabled: item.enabled,
        })
          .then(res => {
            this.$swal({
              title: '设置成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            this.$swal({
              title: '设置失败',
              icon: 'error',
              text: '原因:' + err.response.data.msg,
              confirmButtonText: '知道了',
            })
          })
      },
      exportExcel () {
        http.file.uploadExcel(this.exportExcelFile)
          .then(res => {
            this.excelData = res.data.data
            console.log(this.excelData)
          })
      },
      delExcelData (index) {
        this.excelData.splice(index, 1)
      },
      saveExcelData () {
        console.log(this.excelData)
        const userData = []
        this.excelData.map(val => {
          const userInfo = {
            email: val[0],
            mobile: val[1],
            name: val[2],
            nickName: val[3],
            office: val[4],
            orgId: val[5],
            password: val[6],
            position: val[7],
          }
          userData.push(userInfo)
        })
        console.log(userData)
        http.user.batchCreation('11', userData)
          .then(res => {
            this.$swal({
              title: '批量创建成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
      },
      // 下载导入用户模板
      downloadTemplate () {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = 'https://ebo.kp.sdcom.gov.cn/import/%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20-%20%E7%94%A8%E6%88%B7.xlsx'
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
  .dialog_box{
    overflow: inherit;
  }
  .form_box{
    max-height: 60vh;
    overflow: auto;
  }
  .title{
    font-size: 18px;
    text-align: left;
  }
</style>
